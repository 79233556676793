import React from "react";
import logo from './meta-logo.png';
import './App.css';

export default class Header extends React.Component {
    render() {
        return (
            <header>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans"/>
                <img src={logo} alt="" height="70px"/>
                <p className="title">Невидмый мир | Система оплаты</p>
            </header>
    )
    }

    }
  