import React, {useEffect} from "react";
import './Oplati.css';
import Header from "./Header";
import oplati from './oplati_logo.svg';
import {Link, useSearchParams} from "react-router-dom";
import axios from 'axios';
import QRCode from 'qrcode.react';

export default function Oplati() {
    const [search] = useSearchParams();
    const orderNumber = search.get('order');
    const orderSum = search.get('sum');
    const payID = search.get('payId');
    const QRurl = search.get('url');
    window.PAY_URL = document.URL;

    let a = 0;

    function akol(kol) {
        a += kol;
        return a
    };

    let config = {
        headers: {
            regNum: 'OPL000062433',
            password: 'EUBYGKoLCZafi2U'
        }
    };

    function status_reques(payID) {
        const alert_bg = document.getElementById('alert');
        const alert_text = document.getElementById('alert_text');
        axios.get('https://cashboxapi.o-plati.by/ms-pay/pos/payments/' + payID, config).then(response => {
            if (response.data.status === 5) {
                alert_text.innerHTML = '<a id="reload">Начните оплату заново</a><p>Вы не успели подтвердить или не подтвердили платеж</p>';
                alert_bg['id'] = 'alert_3';
                document.getElementById("reload").addEventListener("click", () => {
                    window.location.replace("https://invisible-world.by/");
                });
            };
            if (response.data.status === 1) {
                alert_text.innerText = 'Заказ успешно оплачен';
                alert_bg["id"] = 'alert_2';
                setTimeout(() => {
                    window.location.replace("https://invisible-world.by/");
                }, 3000);
            }
            if (response.data.status === 0) {
                setTimeout(() => {
                    status_reques(payID);
                    akol(1);
                }, 3000);
            }
            ;

        })
    }

    useEffect(() => {
        status_reques(payID);
    });
    return (
        <div>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans"/>
            <Header/>
            <h1 className="oplati_h1">Оплата заказа №{orderNumber}</h1>
            <h2 className="oplati_h2">Сумма к оплате: {orderSum} р.</h2>
            <div className="table">
                <div className="table_text">
                    <img src={oplati} alt=''/>
                    <p className="text1">Для оплаты заказа откройте мобильное приложение Оплати и отсканируйте
                        QR-код.</p>
                    <Link to="/about_opl">Как это работает?</Link>
                    <div className="button_pay" onClick={() => {
                        window.location.href = 'https://getapp.o-plati.by/map/?app_link=' + QRurl
                    }}>Оплатить
                    </div>
                </div>
                <div className="oplati_qr">
                    <h3 className="oplati_h3">Отсканируйте QR-код:</h3>
                    <QRCode className="qr" value={QRurl} renderAs="svg"/>
                    <div id="alert">
                        <p id="alert_text">Мы ожидаем подтверждения платежа</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
