import oplati_logo from './oplati_logo.svg';
import Header from './Header';
import './About.css';

export default function About() {
    return (
    <div className="App">
        <Header/>
        <h1>Cпособы оплаты:</h1>
        <div className="body_container">
            <div className="container">
                <img src={oplati_logo} alt="" height="23px"/>
                <h2>Оплата через приложение Оплати</h2>
                <p>Бесплатный платежный сервис, который работает с использованием QR-кодов.</p>
                <p>Скачать приложение «Оплати» вы можете в App Store, Google Play или AppGallery: <a
                    href="https://getapp.o-plati.by">https://getapp.o-plati.by</a>.
                    Пополнение электронного кошелька возможно с карточек любых банков без комиссии,
                    через ЕРИП, банковским
                    переводом
                    или переводом с другого кошелька «Оплати».</p>
                <p>После выбора способа оплаты через систему «Оплати», вы увидите уникальный QR-код,
                    который позволит вам
                    произвести платеж, или кнопку с переходом в приложение на мобильном устройстве.
                    Уникальный QR-код будет доступен к сканированию приложением на вашем телефоне в
                    течение 60 секунд. В случае,
                    если время на оплату истекло, у вас будет возможность получить новый QR-код для
                    завершения оплаты.</p>
                <p>Электронный чек оплаты можно посмотреть в истории платежей приложения «Оплати».
                    В случае аннулирования заказа, возврат осуществляется на электронный кошелёк сервиса
                    «Оплати», с которого была
                    произведена оплата.</p>
            </div>
            <div className="next_button" onClick={()=>{window.location.href = window.PAY_URL}}>Назад</div>
        </div>
    </div>
    )}