import {useRouteError} from "react-router-dom";
import Header from './Header';
import './Error.css';

export default function ErrorPage() {
    const error = useRouteError();

    return (
        <div id="error-page">
            <Header/>
            <h1 className="error_h1">Упс!</h1>
            <p className="error_p">Извините, произошла непредвиденная ошибка.</p>
            <p style={{textAlign: 'center', marginTop: '10px'}}>
                <i className="error_i">{error.statusText || error.message}</i>
            </p>
        </div>
    );
}